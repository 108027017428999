<template>
  <div
    class="krpano__wrapper"
    @keydown.esc="
      titleModal = false;
      infoModal = false;
      imageModal = false;
      videoModal = false;
    "
  >
    <transition-group name="fade-splash" mode="out-in">
      <SplashScreen
        v-if="showingSplash && loading"
        @close="showingSplash = false"
        key="splash"
      />
      <TutorialScreen
        v-if="
          showTutorial &&
          !showingSplash &&
          !loading &&
          currentTour &&
          currentPano
        "
        :firstPano="currentTour.panos.indexOf(currentPano) === 0"
        @close="showTutorial = false"
        key="tutorial"
      />
    </transition-group>

    <InfoModal
      class="info-modal"
      :open="infoModal"
      :content="modalContent"
      @close="
        infoModal = false;
        modalContent = {};
      "
    />
    <ImageModal
      class="image-modal"
      :open="imageModal"
      :content="modalContent"
      @close="
        imageModal = false;
        modalContent = {};
      "
    />
    <VideoModal
      class="video-modal"
      :open="videoModal"
      :content="modalContent"
      @close="
        videoModal = false;
        modalContent = {};
      "
    />
    <TitleModal
      v-if="currentPano"
      class="title-modal"
      :open="titleModal"
      :pano="modalContent"
      @close="
        titleModal = false;
        modalContent = {};
      "
    />
    <SideMenu
      class="nav__side-menu"
      :categories="categories"
      :active="currentPano"
      :loadPano="loadPano"
      :open="sideMenu"
      @close="sideMenu = false"
    />

    <a v-if="currentTour && !embedded" class="gui__logo" :href="`/${$locale}`"
      ><img :src="$l.logo" alt="Logo"
    /></a>

    <transition name="fade">
      <div
        class="gui"
        v-if="
          !infoModal &&
          !imageModal &&
          !videoModal &&
          !titleModal &&
          !sideMenu &&
          !showTutorial
        "
      >
        <div
          v-if="webVRCapable && !webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="startWebVR">{{ $t.uiEnterVr }}</a>
        </div>
        <div
          v-if="webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="exitWebVR">{{ $t.uiExitVr }}</a>
        </div>

        <div v-if="currentPano" class="gui__title">
          <h2 v-if="currentPano.floor">{{ currentPano.floor.title }}</h2>
          <h1>{{ currentPano.title }}</h1>
          <ReadMore
            v-if="currentPano.description"
            :text="currentPano.description"
            :max="150"
            @open-more="
              titleModal = true;
              modalContent = {
                title: currentPano.title,
                description: currentPano.description,
                floor: currentPano.floor,
                audio: currentPano.audio,
              };
            "
          />
        </div>

        <a href="#!" class="side-menu__button" @click.prevent="sideMenu = true">
          <img
            src="/assets/img/icons/icon-menu.svg"
            alt="menu icon"
            class="side-menu__button-icon"
          />
          <span>{{ $t.uiMenuLabel }}</span>
        </a>
      </div>
    </transition>
    <div id="krpano"></div>
  </div>
</template>

<script>
import Vue from "vue";
import InfoModal from "@/components/InfoModal.vue";
import ImageModal from "@/components/ImageModal.vue";
import VideoModal from "@/components/VideoModal.vue";
import TitleModal from "@/components/TitleModal.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import Hotspot from "@/components/Hotspot.vue";
import ReadMore from "@/components/ReadMore.vue";
import SideMenu from "@/components/SideMenu.vue";
import TutorialScreen from "@/components/TutorialScreen.vue";
import virtualTourApi from "@/api";
import {
  addExitVrHotspot,
  addVrHotspots,
  removeVrHotspots,
} from "@/helpers/vr";
import { isTouchDevice } from "@/helpers";

export default {
  name: "Krpano",
  components: {
    Hotspot,
    InfoModal,
    ImageModal,
    VideoModal,
    TitleModal,
    ReadMore,
    SplashScreen,
    TutorialScreen,
    SideMenu,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    pageTitle() {
      if (this.currentPano && this.currentTour) {
        return `${this.currentPano.title} - ${this.currentTour.title}`;
      }
    },
  },
  data() {
    return {
      showTutorial: true,
      showingSplash: true,
      loading: true,
      sideMenu: false,
      titleModal: false,
      infoModal: false,
      imageModal: false,
      videoModal: false,
      modalContent: {},
      webVRCapable: false,
      webVRActive: false,
      categories: [],
      panos: [],
      panorama: this.$route.params.panorama
        ? this.$route.params.panorama
        : null,
      currentTour: null,
      currentPano: null,
      previousPano: null,
      embedded: this.$route.query.embed === "true",
    };
  },
  mounted() {
    this.fetchPanos();
    this.initWindow();
  },
  beforeRouteUpdate(to, from, next) {
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      let previousPano = this.panos.find((p) => p.slug == to.params.panorama);
      if (previousPano && this.currentPano !== previousPano) {
        this.currentPano = previousPano;
        window.krpanoObj.call(
          `loadscene('${previousPano.name}', null, MERGE, BLEND(0.5));`
        );
        this.sendGtmView(
          `${this.currentPano.title}`,
          `/${this.$locale}/${this.currentPano.slug}`
        );
      } else {
        history.back();
      }
    }
    next();
  },
  methods: {
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true;
      };
      window.stopLoading = () => {
        this.loading = false;
        this.showingSplash = false;
        // setTimeout(() => (this.loading = false), 500);
      };

      window.popStateDetected = false;
      window.addEventListener("popstate", () => {
        window.popStateDetected = true;
      });

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {
        this.webVRCapable = vrStatus;
      };

      // Init hotspots
      window.loadHotspot = async (spot, pano) => {
        let hotspot = (
          await virtualTourApi.getHotspot(
            this.currentTour.id,
            pano.name,
            spot.name
          )
        ).data;
        if (hotspot.linkedScene || hotspot.linkedPano) {
          let linkedPano = (
            await virtualTourApi.getPano(
              this.currentTour.id,
              hotspot.linkedPano
                ? hotspot.linkedPano.panoName
                : hotspot.linkedScene
            )
          ).data;
          this.loadHotspotStyle(spot, hotspot, linkedPano);
        } else {
          this.loadHotspotStyle(spot, hotspot, null);
        }
      };

      // Handle VR hotspot click
      window.handleVrHotspotClick = (type, title, slug, name) => {
        switch (type) {
          case "navigation":
            this.loadPano(null, { title: title, name: name, slug: slug });
            break;
          case "exit_vr":
            this.exitWebVR();
            break;
          default:
            break;
        }
      };
    },
    async fetchPanos() {
      virtualTourApi.getPanosForTour().then((res) => {
        this.currentTour = res.data;
        this.$store.state.currentTour = this.currentTour;

        this.panos = res.data.panos;
        virtualTourApi.getCategories().then((res) => {
          this.categories = res.data;
        });

        if (this.panorama) {
          // Pano selected - init Krpano with certain pano
          let panoToShow = this.panos.find((p) => p.slug == this.panorama);
          this.currentPano = panoToShow;

          if (this.panos.indexOf(this.currentPano) !== 0) {
            this.showTutorial = false;
          }

          if (panoToShow) {
            // Pano is new so let load on init
            this.initTour(panoToShow);
            return;
          }
        }
        // No start pano selected - init Krpano from start
        this.currentPano = res.data.panos[0];
        let panoToShow = this.currentPano;

        if (this.panos.indexOf(this.currentPano) !== 0) {
          this.showTutorial = false;
        }

        this.initTour(panoToShow);
        return;
      });
    },
    initTour(startPano) {
      // Init krpano with the current tour
      var xml = `/assets/vtours/${
        this.currentTour.slug
      }/tour.xml?h=${+new Date()}`;
      let { embedpano } = window;

      embedpano({
        xml: xml,
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        consolelog: process.env.NODE_ENV === "development",
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global");
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag";
            krpanoObj.control.touch = "drag";
          } else {
            krpanoObj.control.mouse = "follow";
            krpanoObj.control.touch = "follow";
          }
        },
        initvars: {
          basePath: "/assets/js",
          partialPath: "/assets/js/partials",
          pluginPath: "/assets/js/plugins",
          assetPath: "/assets/js/assets",
          dev: process.env.NODE_ENV === "development",
          primaryColor: this.$l.primaryColor.replace("#", "0x"),
          secondaryColor: this.$l.secondaryColor.replace("#", "0x"),
          accentColor: this.$l.accentColor.replace("#", "0x"),
        },
      });

      if (startPano) {
        this.loadPano(null, startPano);
      } else if (!this.panorama) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/${this.panorama}`,
        });
      }

      if (!startPano) {
        this.sendGtmView(
          `${this.currentPano.title}`,
          `/${this.$locale}/${this.currentPano.slug}`
        );
      }
    },
    loadPano(event, pano) {
      if (event) event.preventDefault();
      if (!pano) return;

      this.currentPano = pano;
      window.krpanoObj.call(
        `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
      );

      this.sendGtmView(
        `${this.currentPano.title}`,
        `/${this.$locale}/${this.currentPano.slug}`
      );

      if (this.panorama !== pano.slug) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/${pano.slug}`,
        });
      }

      if (this.webVRActive) {
        addExitVrHotspot(this.$t.uiExitVr);
      }
    },
    async loadHotspotStyle(spot, hotspot, panorama) {
      hotspot.linkedPano = panorama;

      let template = `
      <div class="hotspot__wrapper">
        <div id="hotspot-${hotspot.id}"></div>
        <div class="hotspot__outer"></div>
      </div>
      `;

      // Set basic config
      spot.type = "text";
      spot.renderer = "css3d";
      spot.html = template;
      spot.zoom = false;
      spot.distorted = false;

      // If I want a crosshair?
      // spot.flying = 1;

      if (spot.sprite) {
        // Set the id of the moving hotspot
        spot.sprite.id = `hotspot__locator--${hotspot.id}`;

        // Init hotspot component onto template
        const HotspotCtor = Vue.extend(Hotspot);
        const hotspotElement = new HotspotCtor({
          propsData: {
            webVRActive: this.webVRActive,
            tour: this.currentTour,
            pano: this.currentPano,
            hotspot: hotspot,
          },
          parent: this,
        }).$mount(`#hotspot-${hotspot.id}`);

        // Set click action
        spot.onclick = () => {
          hotspotElement.handleClick();
        };

        // Listen to hotspot
        hotspotElement.$on("navigate", (linkedPano) => {
          this.loadPano(null, linkedPano);
        });
        hotspotElement.$on("info", (content) => {
          this.modalContent = content;
          this.infoModal = true;
        });
        hotspotElement.$on("image", (content) => {
          this.modalContent = content;
          this.imageModal = true;
        });
        hotspotElement.$on("video", (content) => {
          this.modalContent = content;
          this.videoModal = true;
        });
      }
    },
    startWebVR() {
      addVrHotspots({
        continue: this.$t.uiContinueLabel,
        exit: this.$t.uiExitVr,
      });
      this.webVRActive = true;
      krpanoObj.call("webvr_onentervr();");
      krpanoObj.call("webvr.enterVR();");
      this.sendGtmEvent("webvr-open", { webvr_pano: this.currentPano.title });
    },
    exitWebVR() {
      removeVrHotspots();
      this.webVRActive = false;
      krpanoObj.call("webvr.exitVR();");
      krpanoObj.call("webvr_onexitvr();");
      krpanoObj.call("lookto(0, 0)");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/gui.scss";

.krpano__wrapper {
  background: var(--accent-color);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}
</style>
