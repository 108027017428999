<template>
  <ul class="languages">
    <li v-for="locale in availableLocales" :key="locale.key">
      <a
        href=""
        @click.prevent="selectLocale(locale.key)"
        :class="`small-caps ${locale === selected ? 'selected' : ''}`"
      >
        {{ locale.label }}</a
      >
    </li>
  </ul>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "Languages",
  data() {
    return {
      selected: localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : this.$locale,
      availableLocales: [],
    };
  },
  mounted() {
    virtualTourApi
      .getAvailableLocales()
      .then((res) => (this.availableLocales = res.data));
  },
  methods: {
    selectLocale(locale) {
      let splitPathname = window.location.pathname.split("/");
      const indexOfLocale = splitPathname.indexOf(this.$locale);
      splitPathname[indexOfLocale] = locale;

      this.selected = locale;
      localStorage.setItem("locale", locale);
      this.$locale = locale;

      // Refetch data or go to craft page
      window.location.href = splitPathname.join("/");
    },
  },
};
</script>

<style lang="scss">
.languages {
  display: none;
  align-items: center;
  justify-content: center;
  grid-gap: 6.4rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 3.2rem;

  * {
    color: $color-white;
  }

  a {
    text-decoration: none;
    transition: all 0.1s linear;
    font-size: 1.6rem;
    text-transform: uppercase;

    &:hover {
      opacity: 0.5;
    }
  }

  @include for-tablet-landscape-up {
    display: flex;
  }

  @include for-giant-desktop-up {
    grid-gap: rs(64);
    bottom: rs(32);

    a {
      font-size: rs(16);
    }
  }
}
</style>
