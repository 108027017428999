const parseYoutubeLink = (url) => {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

const parseVimeoLink = (url) => {
  let regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  let match = url.match(regExp);
  return match[5];
};

const isTouchDevice = () => {
  return "ontouchstart" in document.documentElement && window.innerWidth <= 900;
};

export { parseYoutubeLink, parseVimeoLink, isTouchDevice };
