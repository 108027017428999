import Vue from "vue";
import VueRouter from "vue-router";
import * as views from "../views";

// Set init locale
if (localStorage.getItem("locale")) {
  Vue.prototype.$locale = localStorage.getItem("locale");
} else {
  Vue.prototype.$locale = process.env.VUE_APP_FALLBACK_LOCALE;
}
localStorage.setItem("locale", Vue.prototype.$locale);

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale/:panorama?",
    name: "Tour - Virtual tour",
    component: views.Krpano,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.params.locale) {
    Vue.prototype.$locale = to.params.locale;
    localStorage.setItem("locale", to.params.locale);
  }
  next();
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

export default router;
