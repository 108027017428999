<template>
  <div
    :class="`image-modal__container ${
      open ? 'image-modal__container_open' : ''
    }`"
    aria-modal="true"
    :aria-labelledby="content.title"
    :aria-describedby="content.caption"
  >
    <!-- Close button -->
    <a
      class="btn--menu close gui__element gui__element--top-right"
      href="#!"
      @click.prevent="$emit('close')"
    >
      <img :src="`/assets/img/icons/icon-close.svg`" />
    </a>
    <div class="image-modal__inner">
      <!-- Content -->
      <div :class="`swiper ${content.images ? '' : 'hidden'}`">
        <div class="swiper-wrapper">
          <div
            v-for="(image, index) in content.images"
            :key="index"
            class="swiper-slide img__wrapper swiper-no-swiping"
          >
            <img :src="image.url" alt="Modal image" class="lazyload" />
          </div>
        </div>

        <div
          :class="`swiper-navigation-container ${
            content.images && content.images.length > 1 ? '' : 'hidden'
          }`"
        >
          <div class="swiper-navigation swiper-button-prev"></div>
          <div class="swiper-navigation swiper-button-next"></div>
        </div>
      </div>
      <p>{{ content.caption }}</p>
    </div>
    <div class="image-modal__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import Swiper, { Navigation } from "swiper";

export default {
  name: "ImageModal",
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    });

    Swiper.use([Navigation]);
    new Swiper(".swiper", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.image-modal {
  &__container {
    position: absolute;
    background: rgba(#fff, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    @include for-tablet-landscape-up {
      background: rgba(#424242, 0.9);
    }

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .image-modal__inner {
        opacity: 1;

        * {
          opacity: 1;
        }
      }

      .image-modal__outer {
        pointer-events: all;
      }

      .close {
        opacity: 1 !important;
      }
    }
  }

  &__inner {
    position: absolute;
    z-index: 1003;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-content: center;
    max-width: 90%;
    height: auto;

    bottom: 0;
    right: 0;
    width: 100%;

    * {
      opacity: 0;
      font-weight: normal;
      height: 100%;
    }

    figure {
      height: auto !important;
    }

    p {
      color: $color-black;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 2rem;

      @include for-tablet-landscape-up {
        color: $color-white;
      }
    }

    @include for-tablet-landscape-up {
      max-width: 70%;
      height: 80%;
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    background: transparent;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.1s linear;

      // #6F6F6F
      filter: invert(45%) sepia(0%) saturate(1%) hue-rotate(138deg)
        brightness(95%) contrast(89%);

      @include for-tablet-landscape-up {
        // #fff
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(199deg)
          brightness(104%) contrast(104%);
      }
    }

    &:hover img {
      opacity: 0.5;
    }
  }

  @include for-giant-desktop-up {
    &__inner {
      p {
        margin-top: rs(12);
        font-size: rs(16);
        line-height: rs(20);
      }
    }

    .close {
      width: rs(24);
      height: rs(24);
    }
  }
}

.swiper {
  width: 100%;
  height: auto !important;
  margin: 2.4rem 0 1.8rem 0;

  &.hidden {
    display: none;
  }

  .img__wrapper {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  &-navigation {
    position: absolute;
    top: unset;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: all;

    &-container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: fixed;
      height: 6rem !important;
      z-index: 1;
      width: 100%;
      pointer-events: none;

      &.hidden {
        display: none;
      }
    }

    &.swiper-button-next {
      right: 0;
      left: unset;
      background-image: url("/assets/img/icons/icon-arrow--next.svg");

      @include for-tablet-landscape-up {
        right: -6rem;
      }
    }

    &.swiper-button-prev {
      left: 0;
      background-image: url("/assets/img/icons/icon-arrow--prev.svg");

      @include for-tablet-landscape-up {
        left: -6rem;
      }
    }

    &:after {
      content: "" !important;
    }
  }

  @include for-giant-desktop-up {
    margin: rs(24) 0 rs(18) 0;

    &-navigation {
      width: rs(60);
      height: rs(60);

      &-container {
        height: rs(60) !important;
      }

      &.swiper-button-next {
        right: rs(-60);
      }

      &.swiper-button-prev {
        left: rs(-60);
      }
    }
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 0.5s linear;
}
</style>
