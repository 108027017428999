<template>
  <div
    :class="`video-modal__container ${
      open ? 'video-modal__container_open' : ''
    }`"
    aria-modal="true"
    :aria-labelledby="content.title"
    :aria-describedby="content.caption"
  >
    <!-- Close button -->
    <a
      class="btn--menu close gui__element gui__element--top-right"
      href="#!"
      @click.prevent="handleClose"
    >
      <img :src="`/assets/img/icons/icon-close.svg`" />
    </a>
    <div class="video-modal__inner" v-if="content.videoUrl">
      <!-- Content -->
      <video
        id="video"
        autoplay
        controls
        controlslist="nodownload noremoteplayback noplaybackrate"
        disablePictureInPicture
      >
        <source :src="content.videoUrl" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="video-modal__outer" @click="handleClose"></div>
  </div>
</template>

<script>
export default {
  name: "VideoModal",
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.handleClose();
      }
    });
  },
  methods: {
    handleClose() {
      if (this.content.videoUrl) {
        const video = document.getElementById("video");
        video.pause();
        video.currentTime = 0;
      }
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.video-modal {
  &__container {
    position: absolute;
    background: rgba(#fff, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    @include for-tablet-landscape-up {
      background: rgba(#424242, 0.9);
    }

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .video-modal__inner {
        opacity: 1;

        * {
          opacity: 1;
        }
      }

      .video-modal__outer {
        pointer-events: all;
      }

      .close {
        opacity: 1 !important;
      }
    }
  }

  &__inner {
    position: absolute;
    z-index: 1003;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-content: center;
    max-width: 90%;
    height: auto;
    bottom: 0;
    right: 0;
    width: 100%;

    * {
      opacity: 0;
      font-weight: normal;
      height: 100%;
    }

    p {
      color: $color-black;
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 2rem;

      @include for-tablet-landscape-up {
        color: $color-white;
      }
    }

    @include for-tablet-landscape-up {
      max-width: 70%;
      height: 80%;
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    background: transparent;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.1s linear;

      // #6F6F6F
      filter: invert(45%) sepia(0%) saturate(1%) hue-rotate(138deg)
        brightness(95%) contrast(89%);

      @include for-tablet-landscape-up {
        // #fff
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(199deg)
          brightness(104%) contrast(104%);
      }
    }

    &:hover img {
      opacity: 0.5;
    }
  }

  video {
    width: 100%;
    height: auto;
    // height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include for-giant-desktop-up {
    .close {
      width: rs(24);
      height: rs(24);
    }
  }
}
</style>
