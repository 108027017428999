<template>
  <div class="audio">
    <!-- Button -->
    <div class="audio__action audio__icon" @click="toggleAudio">
      <img v-if="playing" :src="`/assets/img/icons/icon-pause.svg`" />
      <img v-else :src="`/assets/img/icons/icon-play.svg`" />
    </div>

    <!-- Trackbar -->
    <div class="audio__seeker" @click="setTimeAudio">
      <div ref="seeker" class="seeker"></div>
    </div>

    <!-- Timer -->
    <p>
      <span ref="current">00:00</span>
      /
      <span ref="duration">00:00</span>
    </p>

    <!-- Mute -->
    <div @click="toggleMute" class="audio__icon audio__mute">
      <img v-if="!muted" :src="`/assets/img/icons/icon-mute--off.svg`" />
      <img v-else :src="`/assets/img/icons/icon-mute--on.svg`" />
    </div>

    <!-- Hidden audio tag -->
    <audio
      id="audio"
      controls
      @ended="handleEnd"
      preload="metadata"
      @loadedmetadata="handleMetaData"
      ref="source"
    >
      <source :src="src" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
export default {
  name: "Audio",
  props: ["src"],
  data() {
    return {
      audio: this.$refs.source,
      playing: false,
      muted: false,
      progress: 0,
    };
  },
  mounted() {
    this.audio = this.$refs.source;

    // Display progress
    this.audio.ontimeupdate = () => {
      if (this.audio.currentTime === this.audio.duration) {
        this.audioState = "stopped";
      } else {
        this.progress = Math.floor(
          (this.audio.currentTime / this.audio.duration) * 100
        );
        if (this.$refs.seeker) {
          this.$refs.seeker.style.width = `${this.progress}%`;

          this.$refs.current.innerText = this.formatTime(
            this.audio.currentTime
          );
          this.$refs.duration.innerText = this.formatTime(this.audio.duration);
        }
      }
    };
  },
  methods: {
    handleMetaData() {
      this.$refs.duration.innerText = this.formatTime(this.audio.duration);
    },
    stop() {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.muted = false;
      this.playing = false;
    },
    handleEnd() {
      this.playing = false;
      this.progress = 100;
      this.$refs.seeker.style.width = `${this.progress}%`;
    },
    toggleMute() {
      this.muted = !this.muted;
      this.audio.muted = this.muted;
    },
    toggleAudio() {
      if (!this.playing) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
      this.playing = !this.playing;
    },
    setTimeAudio(event) {
      let bcr = event.target.getBoundingClientRect();
      let percentage = (event.clientX - bcr.left) / bcr.width;
      this.audio.currentTime = this.audio.duration * percentage;
    },
    formatTime(seconds) {
      let sec = Math.floor(seconds);
      let min = Math.floor(sec / 60);
      min = min >= 10 ? min : "0" + min;
      sec = Math.floor(sec % 60);
      sec = sec >= 10 ? sec : "0" + sec;
      return min + ":" + sec;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio {
  width: 100%;
  background: #dbe2e9;
  padding: 0.3rem 1.2rem 0.3rem 0.3rem;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  min-height: 5rem;

  @include for-tablet-landscape-up {
    padding: 0.3rem;
  }

  &__icon {
    height: 4.4rem;
    width: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 75%;
      height: 75%;
    }
  }

  &__seeker {
    cursor: pointer;
    flex: 1;
    height: 0.5rem;
    background: white;
    position: relative;
    overflow: hidden;

    .seeker {
      pointer-events: none;
      background: var(--accent-color);
      height: 100%;
      left: 0;
      width: 0;
      position: relative;
    }
  }

  &__action {
    background: var(--accent-color);
    margin-right: 0.6rem;

    @include for-tablet-landscape-up {
      margin-right: 0;
    }
  }

  p {
    margin-left: 0.6rem;

    @include for-tablet-landscape-up {
      margin-left: 0;
    }
  }

  &__mute {
    display: none;

    @include for-tablet-landscape-up {
      display: flex;
    }
  }

  audio {
    display: none;
  }

  @include for-giant-desktop-up {
    padding: rs(3);
    grid-gap: rs(20);
    min-height: rs(50);

    &__icon {
      height: rs(44);
      width: rs(44);
    }

    &__seeker {
      height: rs(5);
    }
  }
}
</style>
