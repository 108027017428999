<template>
  <div
    :class="`info-modal__container ${open ? 'info-modal__container_open' : ''}`"
    aria-modal="true"
    :aria-labelledby="content.title"
    :aria-describedby="content.content"
  >
    <div :class="`info-modal__inner ${content.images ? 'has-images' : ''}`">
      <!-- Close button -->
      <a
        class="btn--menu close gui__element gui__element--top-right"
        href="#!"
        @click.prevent="handleClose"
      >
        <img :src="`/assets/img/icons/icon-close.svg`"
      /></a>

      <!-- Content -->
      <h1>{{ content.title }}</h1>

      <div :class="`swiper ${content.images ? '' : 'hidden'}`">
        <div class="swiper-wrapper">
          <div
            v-for="(image, index) in content.images"
            :key="index"
            class="swiper-slide img__wrapper swiper-no-swiping"
            @click="tinyIndex = index"
          >
            <img :src="image.thumb" alt="Modal image" class="lazyload" />
          </div>
        </div>

        <div
          :class="`swiper-navigation-container ${
            content.images && content.images.length > 1 ? '' : 'hidden'
          }`"
        >
          <div class="swiper-navigation swiper-button-prev"></div>
          <div class="swiper-navigation swiper-button-next"></div>
        </div>
      </div>

      <Audio v-if="content.audio" :src="content.audio" ref="audio" />

      <p v-html="content.content"></p>
    </div>
    <div class="info-modal__outer" @click="handleClose"></div>
    <Tinybox
      v-if="content.images"
      v-model="tinyIndex"
      :images="content.images.map((i) => i.url)"
      no-thumbs
    />
  </div>
</template>

<script>
import Audio from "@/components/Audio.vue";
import Swiper, { Navigation } from "swiper";
import Tinybox from "vue-tinybox";

export default {
  name: "InfoModal",
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  components: {
    Audio,
    Tinybox,
  },
  data() {
    return {
      tinyIndex: null,
      activeIndex: 0,
    };
  },
  watch: {
    open() {
      const imgs = document.getElementsByClassName("img__wrapper");
      setTimeout(() => {
        if (this.open) {
          for (let i of imgs) {
            i.style["pointer-events"] = "all";
          }
        } else {
          for (let i of imgs) {
            i.style["pointer-events"] = "none";
          }
        }
      }, 500);
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.handleClose();
      }
    });

    Swiper.use([Navigation]);
    new Swiper(".swiper", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  methods: {
    handleClose() {
      if (this.content.audio) {
        this.$refs.audio.stop();
      }
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.info-modal {
  &__container {
    position: absolute;
    background: rgba(#424242, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .info-modal__outer {
        pointer-events: all;
      }

      .close {
        opacity: 1 !important;
      }
    }
  }

  &__inner {
    background: $color-white;
    padding: 7.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    position: absolute;
    overflow-y: auto;
    z-index: 1003;
    width: 100%;

    * {
      font-weight: normal;
    }

    h1 {
      font-size: 4rem;
      line-height: 4.2rem;
      margin: 1.2rem 0;
      color: var(--primary-color);
    }

    p {
      margin-top: 1.6rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .close {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      background: transparent;
      opacity: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.1s linear;

        // #6F6F6F
        filter: invert(45%) sepia(0%) saturate(1%) hue-rotate(138deg)
          brightness(95%) contrast(89%);
      }

      &:hover img {
        opacity: 0.5;
      }
    }

    @include for-tablet-landscape-up {
      padding: 5.2rem;
      width: 60%;
      height: auto;
      max-height: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      max-height: 80%;
      width: 70rem;

      &.has-images {
        max-height: 80%;
        width: 70rem;
      }
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include for-giant-desktop-up {
    &__inner {
      h1 {
        font-size: rs(40);
        line-height: rs(42);
        margin: rs(12) 0;
      }

      p {
        margin-top: rs(16);
        font-size: rs(16);
        line-height: rs(20);
      }

      .close {
        width: rs(24);
        height: rs(24);
      }

      padding: rs(52);
      width: rs(700);

      &.has-images {
        width: rs(700);
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 30rem;
  min-height: 30rem;
  margin: 0.6rem 0 1.8rem 0;

  &-wrapper {
    height: 30rem;
  }

  &.hidden {
    display: none;
  }

  .img__wrapper {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
    pointer-events: none;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-navigation {
    position: absolute;
    top: unset;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: all;

    &-container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      height: 6rem;
      width: 100%;
      pointer-events: none;
      z-index: 1;

      &.hidden {
        display: none;
      }
    }

    &.swiper-button-next {
      right: 0;
      left: unset;
      background-image: url("/assets/img/icons/icon-arrow--next.svg");
    }

    &.swiper-button-prev {
      left: 0;
      background-image: url("/assets/img/icons/icon-arrow--prev.svg");
    }

    &:after {
      content: "" !important;
    }
  }

  @include for-giant-desktop-up {
    height: rs(300);
    min-height: rs(300);
    margin: rs(6) 0 rs(18) 0;

    &-wrapper {
      height: rs(300);
    }

    &-navigation {
      width: rs(60);
      height: rs(60);

      &-container {
        height: rs(60);
      }
    }
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 0.5s linear;
}
</style>
