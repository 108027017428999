<template>
  <div class="home">
    <Languages class="languages gui__element gui__element--top-right" />
    <h1>{{ $t.interfacePageTitle }}</h1>
    <ul>
      <li v-for="tour in tours" :key="tour.slug">
        <a :href="`/${$locale}/virtual-tour/${tour.slug}`">{{ tour.title }}</a>
      </li>
    </ul>
    <h1>Subdomain links</h1>
    <ul>
      <li>
        <a :href="`http://test1.boilerplate.test/${$locale}/virtual-tour/`"
          >Test1</a
        >
      </li>
      <li>
        <a :href="`http://test2.boilerplate.test/${$locale}/virtual-tour/`"
          >Test2</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import virtualTourApi from "@/api";
import Languages from "@/components/Languages";

export default {
  name: "Home",
  props: ["page"],
  components: {
    Languages,
  },
  data() {
    return {
      tours: [],
    };
  },
  mounted() {
    virtualTourApi.getAllTours().then((res) => (this.tours = res.data));
  },
};
</script>
<style lang="scss">
.home {
  .languages a {
    color: $color-text;
  }
}
</style>
