<template>
  <div
    :class="`side-menu__container ${open ? 'side-menu__container_open' : ''}`"
  >
    <div class="side-menu__inner">
      <div class="header">
        <LanguageDropdown />
        <a
          class="btn--menu close"
          href="#!"
          @click.prevent="$emit('close')"
          tabindex="-1"
        >
          <img :src="`/assets/img/icons/icon-close.svg`"
        /></a>
      </div>
      <h1 class="title">{{ $t.introTitle }}</h1>
      <div class="floors">
        <ul v-for="cat in categories" :key="cat.id">
          <h2 class="small-caps">{{ cat.title }}</h2>
          <li
            v-for="pano in cat.panos"
            :key="pano.id"
            :class="pano.id === active.id ? 'active' : ''"
          >
            <a
              href="#!"
              @click.prevent="
                loadPano(null, pano);
                $emit('close');
              "
            >
              <span>{{ pano.title }} </span>
              <img :src="`/assets/img/icons/icon-caret.svg`"
            /></a>
          </li>
        </ul>
      </div>
      <div class="credit">
        <a href="https://poppr.be/" rel="noopener noreferrer" target="_blank">
          {{ $t.uiPoweredBy }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from "@/components/LanguageDropdown.vue";

export default {
  name: "SideMenu",
  components: {
    LanguageDropdown,
  },
  props: {
    categories: { default: [] },
    active: { default: null },
    open: Boolean,
    loadPano: Function,
    close: Function,
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
  },
};
</script>

<style lang="scss">
$header-height: 7.2rem;
$header-height-4k: rs(72);

.side-menu {
  &__container {
    z-index: 1002;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-out;
    visibility: hidden;
    pointer-events: none;

    &_open {
      .side-menu__inner {
        pointer-events: all;
        opacity: 1;

        .side-menu__outer {
          pointer-events: all;
          opacity: 0.3;
        }
      }
    }
  }

  &__inner {
    background-color: $color-white;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    transition: opacity 0.1s linear 0.1s;
    padding: calc(#{$header-height} + 3.6rem) 2rem 2.4rem 2rem !important;
    visibility: visible;
    z-index: 1003;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include for-tablet-landscape-up {
      align-items: center;
      padding: calc(#{$header-height} + 5.2rem) 10.8rem 3.6rem 10.8rem !important;
    }

    // .title,
    // .floors {
    //   max-width: 122rem;
    // }

    .header {
      position: fixed;
      background: $color-white;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: $header-height;
      border-bottom: 1px solid $color-grey;
      -webkit-box-shadow: -3rem 3rem 0px 0px $color-white;
      box-shadow: -3rem 3rem 0px 0px $color-white;

      & > * {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .close {
        width: 7.2rem;
        padding: 2.4rem;

        img {
          object-fit: cover;
          transition: all 0.1s linear;
          width: 100%;
          height: 100%;

          // #6F6F6F
          filter: invert(45%) sepia(0%) saturate(1%) hue-rotate(138deg)
            brightness(95%) contrast(89%);
        }

        &:hover img {
          opacity: 0.5;
        }
      }
    }

    .title {
      font-size: 4rem;
      line-height: 4.2rem;
      color: var(--primary-color);
      margin-bottom: 2.4rem;
      width: 100%;

      @include for-tablet-landscape-up {
        font-size: 4.8rem;
        line-height: 5.2rem;
        margin-bottom: 5.2rem;
      }
    }

    .floors {
      display: flex;
      flex-direction: column;
      grid-gap: 3.6rem;
      width: 100%;
      margin-bottom: 3.6rem;

      @include for-tablet-landscape-up {
        flex-direction: row;
        justify-content: space-between;
        // flex-wrap: wrap;

        ul {
          // min-width: 37rem;
          width: 100%;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        h2 {
          padding: 2rem 0;
          border-bottom: 1px solid $color-grey;
          font-size: 2.4rem;
          line-height: 3rem;

          @include for-tablet-landscape-up {
            font-size: 2.8rem;
          }
        }

        li {
          border-bottom: 1px solid $color-grey;
          transition: all 0.1s linear;

          a {
            padding: 1.2rem 1rem;
            text-decoration: none;
            color: var(--accent-color);
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              height: 2.2rem;
              transition: all 0.1s linear;
            }
          }

          &:hover,
          &.active {
            background: lighten($color-grey, 30%);

            img {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .credit {
      margin-top: auto;
      align-self: flex-start;

      a {
        font-size: 1.2rem;
        line-height: 2.4rem;
        color: $color-black;
        opacity: 0.5;
        text-decoration: none;
        transition: opacity 0.1s linear;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @include for-giant-desktop-up {
    &__inner {
      padding: calc(#{$header-height-4k} + #{rs(52)}) rs(108) rs(36) rs(108) !important;

      .header {
        height: $header-height-4k;
        border-bottom: rs(1) solid $color-grey;
        -webkit-box-shadow: rs(-30) rs(30) 0px 0px $color-white;
        box-shadow: rs(-30) rs(30) 0px 0px $color-white;

        .close {
          width: rs(72);
          padding: rs(24);

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .title {
        font-size: rs(48);
        line-height: rs(52);
        margin-bottom: rs(52);
      }

      .floors {
        grid-gap: rs(36);
        margin-bottom: rs(36);

        ul {
          // min-width: rs(370);

          h2 {
            padding: rs(20) 0;
            border-bottom: rs(1) solid $color-grey;
            line-height: rs(30);
            font-size: rs(28);
          }

          li {
            border-bottom: rs(1) solid $color-grey;

            a {
              padding: rs(12) rs(10);

              img {
                height: rs(22);
              }
            }
          }
        }
      }

      .credit {
        a {
          font-size: rs(12);
          line-height: rs(24);
        }
      }
    }
  }
}
</style>
