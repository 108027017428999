<template>
  <div
    v-if="hotspot.type"
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano ? hotspot.linkedPano.title : ''"
    :data-slug="hotspot.linkedPano ? hotspot.linkedPano.slug : ''"
    :data-name="hotspot.linkedPano ? hotspot.linkedPano.name : ''"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    v-click-outside="handleClickOutside"
  >
    <div
      v-if="hotspot.type.value === 'navigation'"
      :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`"
    >
      <img
        :src="`/assets/img/icons/icon-caret-up.svg`"
        alt="nav hotspot icon"
      />
      <img
        :src="`/assets/img/icons/icon-caret-up.svg`"
        alt="nav hotspot icon"
      />
    </div>
    <div
      v-else
      :class="`hotspot__inner hotspot__inner__${hotspot.type.value} ${
        audioState !== 'stopped' && hotspot.type.value === 'audio'
          ? 'hotspot__inner__audio--playing'
          : ''
      }`"
    >
      <img
        v-if="hotspot.type.value === 'info'"
        :src="`/assets/img/icons/icon-info.svg`"
        alt="info hotspot icon"
      />
      <img
        v-if="hotspot.type.value === 'image'"
        :src="`/assets/img/icons/icon-image.svg`"
        alt="image hotspot icon"
      />
      <img
        v-if="hotspot.type.value === 'video'"
        :src="`/assets/img/icons/icon-video.svg`"
        alt="video hotspot icon"
      />
      <img
        v-if="hotspot.type.value === 'audio' && audioState === 'stopped'"
        :src="`/assets/img/icons/icon-audio.svg`"
        alt="audio hotspot icon"
      />
      <img
        v-if="hotspot.type.value === 'audio' && audioState === 'playing'"
        :src="`/assets/img/icons/icon-audio--pause.svg`"
        alt="audio pause hotspot icon"
      />
      <img
        v-if="hotspot.type.value === 'audio' && audioState === 'paused'"
        :src="`/assets/img/icons/icon-audio--play.svg`"
        alt="audio pause hotspot icon"
      />
      <div
        v-if="hotspot.type.value === 'audio'"
        :id="`audio__seeker-${hotspot.id}`"
        class="progress"
      ></div>
    </div>

    <div
      :class="`hotspot__content ${`hotspot__content--${hotspot.type.value}`}`"
    >
      <span v-if="hotspot.type.value == 'navigation'">
        {{
          hotspot.linkedPano.title
            ? hotspot.linkedPano.title
            : $t.uiContinueLabel
        }}
      </span>
      <span
        v-else-if="
          hotspot.type.value == 'info' ||
          hotspot.type.value == 'image' ||
          hotspot.type.value == 'audio' ||
          hotspot.type.value == 'video'
        "
      >
        {{ hotspot.modal.title }}
      </span>

      <audio
        v-if="hotspot.type.value == 'audio' && hotspot.modal.audio"
        :id="`audio-${hotspot.id}`"
      >
        <source :src="hotspot.modal.audio" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import { addSingleVrHotspot } from "@/helpers/vr";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    webVRActive: Boolean,
    tour: Object,
    pano: Object,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      audio: null,
      audioState: "stopped",
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    let hotspotDOM = document.getElementById(this.hotspot.id);
    if (this.webVRActive && hotspotDOM) {
      addSingleVrHotspot(hotspotDOM, this.$t.uiContinueLabel);
    }

    // Setup audio
    if (this.hotspot.type.value === "audio") {
      // Get dom element
      this.audio = document.getElementById(`audio-${this.hotspot.id}`);

      // Display progress
      this.audio.ontimeupdate = () => {
        if (this.audio.currentTime === this.audio.duration) {
          this.audioState = "stopped";
        } else {
          document.getElementById(
            `audio__seeker-${this.hotspot.id}`
          ).style.width = `${
            (this.audio.currentTime / this.audio.duration) * 100
          }%`;
        }
      };
    }
  },
  methods: {
    handleClickOutside(event) {
      this.clickCount = 0;
    },
    updateClasses() {
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (this.isTouchDevice && this.clickCount < 2) return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = "Info modal";
          break;
        case "image":
          this.$emit("image", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = "Image modal";
          break;
        case "video":
          this.$emit("video", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = "Video modal";
          break;
        case "audio":
          // Only send GTM event when audio is played from start
          if (this.audioState === "stopped") {
            data.modal_on = this.pano.title;
            data.modal_title = this.hotspot.modal.title;
            data.modal_type = "Audio sample";
          } else {
            data = null;
          }
          this.handleAudioClick();
          break;
        default:
          break;
      }

      // Fire GTM event for click
      if (data) {
        this.sendGtmEvent(
          "click-hotspot",
          data,
          this.tour.title,
          this.pano.title
        );
      }
    },
    handleAudioClick() {
      if (this.audioState === "playing") {
        this.audio.pause();
        this.audioState = "paused";
      } else {
        this.audio.play();
        this.audioState = "playing";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hotspot-dimension: 4.6rem;
$hotspot-dimension-info: 3.2rem;

$hotspot-dimension-4k: rs(46);
$hotspot-dimension-info-4k: rs(32);

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // Styling
  width: $hotspot-dimension;
  height: $hotspot-dimension;
  background: var(--secondary-color);
  display: grid;
  place-content: center;
  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  transition: all $basic-transition-200;

  // Appear animation
  opacity: 0;
  width: calc(#{$hotspot-dimension} - 10px);
  height: calc(#{$hotspot-dimension} - 10px);
  animation: hotspot-appear $basic-transition-200 forwards;

  @keyframes hotspot-appear {
    from {
      opacity: 0;
      width: calc(#{$hotspot-dimension} - 10px);
      height: calc(#{$hotspot-dimension} - 10px);
    }
    to {
      opacity: 1;
      width: $hotspot-dimension;
      height: $hotspot-dimension;
    }
  }

  &__info,
  &__image,
  &__video,
  &__audio {
    width: $hotspot-dimension-info;
    height: $hotspot-dimension-info;
    width: calc(#{$hotspot-dimension-info} - 10px);
    height: calc(#{$hotspot-dimension-info} - 10px);
    animation: hotspot-appear-info $basic-transition-200 forwards;
  }

  @keyframes hotspot-appear-info {
    from {
      opacity: 0;
      width: calc(#{$hotspot-dimension-info} - 10px);
      height: calc(#{$hotspot-dimension-info} - 10px);
    }
    to {
      opacity: 1;
      width: $hotspot-dimension-info;
      height: $hotspot-dimension-info;
    }
  }

  &__inner {
    width: $hotspot-dimension;
    height: $hotspot-dimension;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all $basic-transition-200;

    &__navigation {
      background: var(--secondary-color);

      img {
        pointer-events: none;
        position: absolute;
        height: 50%;
        width: 50%;
        object-fit: cover;
        transition: all $basic-transition-300;

        &:first-of-type {
          transform: translateY(0);
        }

        &:last-of-type {
          opacity: 0;
          transform: translateY(1rem);
        }
      }
    }

    &__info,
    &__image,
    &__video,
    &__audio {
      width: $hotspot-dimension-info;
      height: $hotspot-dimension-info;
      background: var(--accent-color);

      img {
        // opacity: 0;
        height: 75%;
        width: 75%;
        transition: all $basic-transition-200;
      }
    }

    .progress {
      display: none;
    }

    &__audio--playing {
      background: $color-white;

      .progress {
        display: block;
        position: absolute;
        width: 0%;
        background: var(--accent-color);
        bottom: 0;
        left: 0;
        height: 0.3rem;
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: $hotspot-dimension;
    height: $hotspot-dimension;
    z-index: 1;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.6rem;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;
    -webkit-box-shadow: 1rem 1rem 0px 0px rgba($color-black, 0.2);
    box-shadow: 1rem 1rem 0px 0px rgba($color-black, 0.2);

    * {
      font-size: 1.5rem;
    }

    transform: translate(-2rem, -50%);
    background: $color-white;

    span {
      color: $color-black;
    }

    audio {
      display: none;
    }

    &--info,
    &--image,
    &--video,
    &--audio {
      left: $hotspot-dimension-info;
      height: $hotspot-dimension-info;

      span {
        color: var(--accent-color);
      }

      * {
        font-size: 1.4rem;
      }
    }
  }

  &:hover,
  &.touched {
    .hotspot__inner__navigation {
      background: var(--secondary-color-darker);

      img {
        &:first-of-type {
          transform: translateY(-0.5rem);
        }

        &:last-of-type {
          opacity: 1;
          transform: translateY(0.5rem);
        }
      }
    }

    .hotspot__inner__info,
    .hotspot__inner__image,
    .hotspot__inner__audio:not(.hotspot__inner__audio--playing) {
      background: var(--accent-color-darker);
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;
      transform: translate(0, -50%);
    }
  }

  @include for-giant-desktop-up {
    // Styling
    width: $hotspot-dimension-4k;
    height: $hotspot-dimension-4k;
    box-shadow: 0 0 rs(32) rs(8) rgba(0, 0, 0, 0.2);

    // Appear animation
    opacity: 0;
    width: calc(#{$hotspot-dimension-4k} - rs(10));
    height: calc(#{$hotspot-dimension-4k} - rs(10));
    animation: hotspot-appear-4k $basic-transition-200 forwards;

    @keyframes hotspot-appear-4k {
      from {
        opacity: 0;
        width: calc(#{$hotspot-dimension-4k} - rs(10));
        height: calc(#{$hotspot-dimension-4k} - rs(10));
      }
      to {
        opacity: 1;
        width: $hotspot-dimension-4k;
        height: $hotspot-dimension-4k;
      }
    }

    &__info,
    &__image,
    &__video,
    &__audio {
      width: $hotspot-dimension-info-4k;
      height: $hotspot-dimension-info-4k;
      width: calc(#{$hotspot-dimension-info-4k} - rs(10));
      height: calc(#{$hotspot-dimension-info-4k} - rs(10));
      animation: hotspot-appear-info $basic-transition-200 forwards;
    }

    @keyframes hotspot-appear-info {
      from {
        opacity: 0;
        width: calc(#{$hotspot-dimension-info-4k} - rs(10));
        height: calc(#{$hotspot-dimension-info-4k} - rs(10));
      }
      to {
        opacity: 1;
        width: $hotspot-dimension-info-4k;
        height: $hotspot-dimension-info-4k;
      }
    }

    &__inner {
      width: $hotspot-dimension-4k;
      height: $hotspot-dimension-4k;

      &__navigation {
        img {
          &:last-of-type {
            transform: translateY(rs(10));
          }
        }
      }

      &__info,
      &__image,
      &__video,
      &__audio {
        width: $hotspot-dimension-info-4k;
        height: $hotspot-dimension-info-4k;
      }

      &__audio--playing {
        .progress {
          height: rs(3);
        }
      }
    }

    &__content {
      left: $hotspot-dimension-4k;
      height: $hotspot-dimension-4k;
      padding: 0 rs(16);
      -webkit-box-shadow: rs(10) rs(10) 0px 0px rgba($color-black, 0.2);
      box-shadow: rs(10) rs(10) 0px 0px rgba($color-black, 0.2);

      * {
        font-size: rs(15);
      }

      transform: translate(rs(-20), -50%);

      &--info,
      &--image,
      &--video,
      &--audio {
        left: $hotspot-dimension-info-4k;
        height: $hotspot-dimension-info-4k;

        * {
          font-size: rs(14);
        }
      }
    }

    &:hover,
    &.touched {
      .hotspot__inner__navigation {
        img {
          &:first-of-type {
            transform: translateY(rs(-5));
          }

          &:last-of-type {
            transform: translateY(rs(5));
          }
        }
      }
    }
  }
}
</style>
