<template>
  <div
    :class="`language-dropdown ${options ? 'language-dropdown--open' : ''}`"
    @click.prevent="options = !options"
  >
    <div class="selected">
      <img :src="`/assets/img/icons/icon-globe.svg`" />
      <span v-if="selectedLocale">{{ selectedLocale.label }}</span>
    </div>
    <ul :class="`options ${options ? 'options--open' : ''}`">
      <li v-for="locale in availableLocales" :key="locale.key">
        <a
          href="#!"
          @click.prevent="selectLocale(locale.key)"
          :class="locale === selected ? 'selected' : ''"
        >
          <span>{{ locale.label }}</span>
          <img :src="`/assets/img/icons/icon-caret.svg`" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "LanguageDropdown",
  data() {
    return {
      selected: localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : this.$locale,
      selectedLocale: null,
      availableLocales: [],
      options: false,
    };
  },
  mounted() {
    virtualTourApi.getAvailableLocales().then((res) => {
      this.availableLocales = res.data;
      this.selectedLocale = this.availableLocales.find(
        (l) => l.key == this.selected
      );
    });
  },
  methods: {
    selectLocale(locale) {
      let splitPathname = window.location.pathname.split("/");
      const indexOfLocale = splitPathname.indexOf(this.$locale);
      splitPathname[indexOfLocale] = locale;

      this.selected = locale;
      localStorage.setItem("locale", locale);
      this.$locale = locale;

      // Refetch data or go to craft page
      window.location.href = splitPathname.join("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.language-dropdown {
  padding: 0 2.4rem 0 1rem !important;
  cursor: pointer;
  position: relative;
  transition: all 0.1s linear;
  min-width: 17rem;
  outline: 1px solid $color-grey;
  display: flex;
  justify-content: flex-start !important;

  * {
    text-transform: uppercase;
  }

  .selected {
    display: flex;
    align-items: center;
    grid-gap: 0.4rem;
    color: #727272;

    img {
      height: 4.2rem;
    }
  }

  &:not(.language-dropdown--open):hover {
    background: lighten($color-grey, 30%);
  }

  .options {
    &--open {
      opacity: 1;
      pointer-events: all;
    }

    list-style-type: none;
    pointer-events: none;
    transition: all 0.1s linear;
    background: $color-white;
    position: absolute;
    top: calc(100% + 0.1rem);
    opacity: 0;
    -webkit-box-shadow: -1.2rem 1.2rem 0px 0px rgba($color-black, 0.1);
    box-shadow: -1.2rem 1.2rem 0px 0px rgba($color-black, 0.1);
    margin: 0;
    padding: 0;

    outline: 1px solid $color-grey;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    * {
      font-size: 1.6rem;
    }

    li {
      border-bottom: 1px solid $color-grey;
      transition: all 0.1s linear;

      a {
        padding: 1.3rem 1.6rem;
        text-decoration: none;
        color: var(--accent-color);
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          height: 2.2rem;
          transition: all 0.1s linear;
        }
      }

      &:hover a {
        text-decoration: underline;
      }
    }

    :last-child {
      border-bottom: 0;
    }
  }

  @include for-giant-desktop-up {
    padding: 0 rs(24) 0 rs(10) !important;
    min-width: rs(170);
    outline: rs(1) solid $color-grey;

    .selected {
      grid-gap: rs(4);

      img {
        height: rs(42);
      }
    }

    .options {
      // top: calc(100% + #{rs(1)});
      -webkit-box-shadow: rs(-12) rs(12) 0px 0px rgba($color-black, 0.1);
      box-shadow: rs(-12) rs(12) 0px 0px rgba($color-black, 0.1);
      outline: rs(1) solid $color-grey;

      * {
        font-size: rs(16);
      }

      li {
        border-bottom: rs(1) solid $color-grey;

        a {
          padding: rs(13) rs(16);

          img {
            height: rs(22);
          }
        }
      }
    }
  }
}
</style>
