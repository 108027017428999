var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"krpano__wrapper",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.titleModal = false;
    _vm.infoModal = false;
    _vm.imageModal = false;
    _vm.videoModal = false;}}},[_c('transition-group',{attrs:{"name":"fade-splash","mode":"out-in"}},[(_vm.showingSplash && _vm.loading)?_c('SplashScreen',{key:"splash",on:{"close":function($event){_vm.showingSplash = false}}}):_vm._e(),(
        _vm.showTutorial &&
        !_vm.showingSplash &&
        !_vm.loading &&
        _vm.currentTour &&
        _vm.currentPano
      )?_c('TutorialScreen',{key:"tutorial",attrs:{"firstPano":_vm.currentTour.panos.indexOf(_vm.currentPano) === 0},on:{"close":function($event){_vm.showTutorial = false}}}):_vm._e()],1),_c('InfoModal',{staticClass:"info-modal",attrs:{"open":_vm.infoModal,"content":_vm.modalContent},on:{"close":function($event){_vm.infoModal = false;
      _vm.modalContent = {};}}}),_c('ImageModal',{staticClass:"image-modal",attrs:{"open":_vm.imageModal,"content":_vm.modalContent},on:{"close":function($event){_vm.imageModal = false;
      _vm.modalContent = {};}}}),_c('VideoModal',{staticClass:"video-modal",attrs:{"open":_vm.videoModal,"content":_vm.modalContent},on:{"close":function($event){_vm.videoModal = false;
      _vm.modalContent = {};}}}),(_vm.currentPano)?_c('TitleModal',{staticClass:"title-modal",attrs:{"open":_vm.titleModal,"pano":_vm.modalContent},on:{"close":function($event){_vm.titleModal = false;
      _vm.modalContent = {};}}}):_vm._e(),_c('SideMenu',{staticClass:"nav__side-menu",attrs:{"categories":_vm.categories,"active":_vm.currentPano,"loadPano":_vm.loadPano,"open":_vm.sideMenu},on:{"close":function($event){_vm.sideMenu = false}}}),(_vm.currentTour && !_vm.embedded)?_c('a',{staticClass:"gui__logo",attrs:{"href":("/" + _vm.$locale)}},[_c('img',{attrs:{"src":_vm.$l.logo,"alt":"Logo"}})]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(
        !_vm.infoModal &&
        !_vm.imageModal &&
        !_vm.videoModal &&
        !_vm.titleModal &&
        !_vm.sideMenu &&
        !_vm.showTutorial
      )?_c('div',{staticClass:"gui"},[(_vm.webVRCapable && !_vm.webVRActive)?_c('div',{staticClass:"gui__webvr gui__element gui__element--top-center"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.startWebVR.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t.uiEnterVr))])]):_vm._e(),(_vm.webVRActive)?_c('div',{staticClass:"gui__webvr gui__element gui__element--top-center"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.exitWebVR.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t.uiExitVr))])]):_vm._e(),(_vm.currentPano)?_c('div',{staticClass:"gui__title"},[(_vm.currentPano.floor)?_c('h2',[_vm._v(_vm._s(_vm.currentPano.floor.title))]):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.currentPano.title))]),(_vm.currentPano.description)?_c('ReadMore',{attrs:{"text":_vm.currentPano.description,"max":150},on:{"open-more":function($event){_vm.titleModal = true;
            _vm.modalContent = {
              title: _vm.currentPano.title,
              description: _vm.currentPano.description,
              floor: _vm.currentPano.floor,
              audio: _vm.currentPano.audio,
            };}}}):_vm._e()],1):_vm._e(),_c('a',{staticClass:"side-menu__button",attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();_vm.sideMenu = true}}},[_c('img',{staticClass:"side-menu__button-icon",attrs:{"src":"/assets/img/icons/icon-menu.svg","alt":"menu icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t.uiMenuLabel))])])]):_vm._e()]),_c('div',{attrs:{"id":"krpano"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }