var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hotspot.type)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],class:("hotspot " + ("hotspot__" + (_vm.hotspot.type.value)) + " " + (_vm.clickCount > 0 && _vm.isTouchDevice ? 'touched' : '')),attrs:{"id":_vm.hotspot.id,"data-title":_vm.hotspot.linkedPano ? _vm.hotspot.linkedPano.title : '',"data-slug":_vm.hotspot.linkedPano ? _vm.hotspot.linkedPano.slug : '',"data-name":_vm.hotspot.linkedPano ? _vm.hotspot.linkedPano.name : '',"data-ath":_vm.hotspot.ath,"data-atv":_vm.hotspot.atv,"data-type":_vm.hotspot.type.value}},[(_vm.hotspot.type.value === 'navigation')?_c('div',{class:("hotspot__inner hotspot__inner__" + (_vm.hotspot.type.value))},[_c('img',{attrs:{"src":"/assets/img/icons/icon-caret-up.svg","alt":"nav hotspot icon"}}),_c('img',{attrs:{"src":"/assets/img/icons/icon-caret-up.svg","alt":"nav hotspot icon"}})]):_c('div',{class:("hotspot__inner hotspot__inner__" + (_vm.hotspot.type.value) + " " + (_vm.audioState !== 'stopped' && _vm.hotspot.type.value === 'audio'
        ? 'hotspot__inner__audio--playing'
        : ''))},[(_vm.hotspot.type.value === 'info')?_c('img',{attrs:{"src":"/assets/img/icons/icon-info.svg","alt":"info hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'image')?_c('img',{attrs:{"src":"/assets/img/icons/icon-image.svg","alt":"image hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'video')?_c('img',{attrs:{"src":"/assets/img/icons/icon-video.svg","alt":"video hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'audio' && _vm.audioState === 'stopped')?_c('img',{attrs:{"src":"/assets/img/icons/icon-audio.svg","alt":"audio hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'audio' && _vm.audioState === 'playing')?_c('img',{attrs:{"src":"/assets/img/icons/icon-audio--pause.svg","alt":"audio pause hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'audio' && _vm.audioState === 'paused')?_c('img',{attrs:{"src":"/assets/img/icons/icon-audio--play.svg","alt":"audio pause hotspot icon"}}):_vm._e(),(_vm.hotspot.type.value === 'audio')?_c('div',{staticClass:"progress",attrs:{"id":("audio__seeker-" + (_vm.hotspot.id))}}):_vm._e()]),_c('div',{class:("hotspot__content " + ("hotspot__content--" + (_vm.hotspot.type.value)))},[(_vm.hotspot.type.value == 'navigation')?_c('span',[_vm._v(" "+_vm._s(_vm.hotspot.linkedPano.title ? _vm.hotspot.linkedPano.title : _vm.$t.uiContinueLabel)+" ")]):(
        _vm.hotspot.type.value == 'info' ||
        _vm.hotspot.type.value == 'image' ||
        _vm.hotspot.type.value == 'audio' ||
        _vm.hotspot.type.value == 'video'
      )?_c('span',[_vm._v(" "+_vm._s(_vm.hotspot.modal.title)+" ")]):_vm._e(),(_vm.hotspot.type.value == 'audio' && _vm.hotspot.modal.audio)?_c('audio',{attrs:{"id":("audio-" + (_vm.hotspot.id))}},[_c('source',{attrs:{"src":_vm.hotspot.modal.audio}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }